export const FirebaseConfig = {
	"projectId": "vapor-driver",
	"appId": "1:740343820655:web:e71ea3e2c7f545a265f89a",
	"databaseURL": "https://vapor-driver-default-rtdb.firebaseio.com",
	"storageBucket": "vapor-driver.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyCjBmlR_IGCxV0OY1NSzEroSrP3ZLspXeo",
	"authDomain": "vapor-driver.firebaseapp.com",
	"messagingSenderId": "740343820655",
	"measurementId": "G-V3T48XLM97"
};